@import 'libs/assets/theme/mixins.scss';

.root {
  display: grid;
  grid-template-columns: 1fr auto;
  // grid-gap: 0.25rem;
  position: relative;
}

.mdPadding {
  padding: 0.375rem 0.5rem;
}

.sPadding {
  padding: 0.5rem;
}
.xsPadding {
  padding: 0.25rem;
}

.submit {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
}

.submitTypography {
  @include ellipsis;
}

.arrowDown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
