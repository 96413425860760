.helperText {
  &:global(.MuiFormHelperText-root) {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
  }
}

.helperTextIcon {
  margin-right: 0.5rem;
}
