.guarded__image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__contain {
    background-size: contain;

    img {
      object-fit: contain;
    }
  }

  &__cover {
    background-size: cover;

    img {
      object-fit: cover;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }

  &.guarded__image--loaded {
    background-color: transparent !important;

    img {
      display: none;
    }
  }

  &.guarded__image--broken {
    width: 100%;
    height: 100%;
    position: relative;

    overflow: hidden;

    img {
      display: none;
    }

    &::before {
      content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDBWMHoiIGZpbGw9Im5vbmUiIC8+PHBhdGggc3R5bGU9ImZpbGw6dmFyKC0tYmFja2dyb3VuZCwjZDBkMGQwKTsiIGQ9Ik0xOSAzSDVjLTEuMSAwLTIgLjktMiAydjE0YzAgMS4xLjkgMiAyIDJoMTRjMS4xIDAgMi0uOSAyLTJWNWMwLTEuMS0uOS0yLTItMnptMCAxNkg1di00LjU4bC45OS45OSA0LTQgNCA0IDQtMy45OUwxOSAxMi40M1YxOXptMC05LjQxbC0xLjAxLTEuMDEtNCA0LjAxLTQtNC00IDQtLjk5LTFWNWgxNHY0LjU5eiIvPjwvc3ZnPg==);
      display: flex;
      font-size: 1rem;
      color: #d0d0d0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 3;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    &::after {
      content: attr(alt);
      font-size: 16px;
      padding: 1rem;
      color: #d0d0d0;
      z-index: 2;
      display: block;
      position: absolute;
      overflow: hidden;

      background-color: #f0f0f0;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  background-repeat: no-repeat;
  background-position: center;
}
