@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/coreVariablesV2';

.categories {
  display: grid;
  justify-content: space-between;

  @include sm {
    grid-template-columns: repeat(8, max-content);
    gap: 1rem;
  }

  @include md {
    grid-template-columns: repeat(12, max-content);
  }

  @include lg {
    gap: 1.875rem 0;
  }
}

.swiperContainer {
  margin: 0 -#{$shopXsThreshold};

  :global {
    .swiper-wrapper {
      padding: 0 $shopXsThreshold;
    }

    .swiper-slide {
      width: auto;
      height: auto;

      /* A workaround for a problem when the last slide is half-cut */
      &:last-child {
        display: flex;
      }

      &:last-child::after {
        content: '';
        width: calc($shopXsThreshold * 2);
      }
    }
  }
}
