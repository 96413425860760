@import 'libs/assets/theme/mixins.scss';

.gray {
  background-color: $lightGray8K;
}

.large {
  padding: 0.5rem 0.375rem;
}

.small {
  padding: 0.25rem 0.375rem;
}

.textBubble {
  border-radius: 0.25rem;
  display: flex;
  gap: 0.25rem;
  width: fit-content;
}

.white {
  background-color: $white;
}
