@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';
@import 'libs/assets/theme/elevations.scss';

.news-card-wrapper {
  padding: 1rem;
  background: $white;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  @include dropShadow1;
}

.image-wrapper {
  width: 100%;
  height: 7rem;

  @include sm {
    height: 9.375rem;
  }

  @include lg {
    height: 11.25rem;
  }
}
.text-wrapper {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 0.5rem;
  color: $darkGray;
}

.theme-wrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 1.4px;
}

.teaser {
  margin-top: 0.5rem;
  // multiline ellipsis
  @include multilineEllipsis;
  -webkit-line-clamp: 4;
}

.title {
  // multiline ellipsis
  @include multilineEllipsis;
  -webkit-line-clamp: 2;
}
