@import 'libs/assets/theme/mixins.scss';

.root {
  position: relative;
  width: 100%;
  white-space: nowrap;
  min-width: 0;
}

.isSingleLineRoot {
  display: flex;
}

.originalTypographyWrap {
  white-space: nowrap;
  line-height: 1;
  width: 100%;
}

.ellipsisTypographyWrap {
  display: flex;
}

.hidden {
  top: 0;
  left: 0;
  visibility: hidden !important;
  height: 0 !important;
}

.part1 {
  @include ellipsis;
}

.rightAdornment {
  display: inline-flex;
}
