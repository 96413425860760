@import 'libs/assets/theme/mixins.scss';

.image {
  align-items: center;
  display: flex;
  & img {
    height: 1.5rem;
    width: 1.5rem;
    object-fit: contain;
  }
}
