@import 'libs/assets/theme/coreVariablesV2.scss';

.textFieldInput {
  width: 100%;
  :global .MuiInputBase-root {
    min-width: 100%;
    font-weight: 700;
    font-size: 1rem;
    background-color: $white;
    border-color: $lightGray;
    :global .Mui-focused fieldset {
      border: 0.0625rem solid $darkGray;
    }
  }

  :global .MuiOutlinedInput-input {
    padding-left: 0.75rem;
    text-align: start;
  }
}

.textFieldInputError {
  :global .MuiFormHelperText-root {
    margin-left: 0;
    margin-right: 0;
  }
  :global .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0.0625rem solid $primaryRed;
  }
}
