@import 'libs/assets/theme/mixins.scss';

.gap {
  gap: 0.5rem;
}

.shipping {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  &Items {
    @include container;
    display: flex;
    flex-direction: column;
    @include cxl {
      flex-direction: row;
      gap: 1rem;
    }
  }
  &Title {
    display: hidden;
    @include cxl {
      display: flex;
    }
  }
}
