@import 'libs/assets/theme/mixins.scss';

.card,
.startPageCard {
  align-items: stretch;
  box-sizing: border-box;
  background-color: $white;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: 0.5rem;
  height: 100%;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  z-index: 1;
}

.details {
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 1rem;
  &Images {
    align-items: center;
    display: flex;
    flex: 1 0 0;
    gap: 0.5rem;
    &More {
      align-items: center;
      display: flex;
      height: 1.5rem;
      justify-content: center;
      width: 1.5rem;
      & svg {
        color: $darkGray21K;
        height: 1.125rem;
        width: 1.125rem;
      }
    }
  }
  &ExtraTopSpacing {
    margin-top: 0.75rem;
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  &Actions {
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-shrink: 0;
  }
  &Labels {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-start;
  }
  &Info {
    align-items: center;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
  @include sm {
    align-items: flex-start;
    flex-direction: row;
    gap: 1rem;
    justify-content: space-between;
    &Name {
      max-width: 36rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.noWhiteSpaceWrap {
  white-space: nowrap;
}

.name {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.shipping {
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  &Info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &Header {
      align-items: center;
      display: flex;
      height: 2rem;
    }
  }

  &Track {
    flex-shrink: 0;
  }
}
.link {
  flex-grow: 1;
  @include sm {
    max-width: calc(($shopMaxWidth - 1.5rem) / 2);
  }
  @include md {
    max-width: calc(($shopMaxWidth - 3rem) / 3);
  }
  @include lg {
    max-width: calc(($shopMaxWidth - 4.5rem) / 4);
  }
}

.shippingInfoAndPreviewImageContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardHead {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.shippingInfoContent {
  width: 100%;
}
