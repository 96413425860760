@import 'libs/assets/theme/mixins.scss';

.root {
  .navigateLeft {
    margin: 0;
    margin-right: 1.5rem;
    &.closeNavButton {
      margin-right: 0.5rem;
    }
  }

  .navigateRight {
    margin: 0;
    margin-left: 1.5rem;
    &.closeNavButton {
      margin-left: 0.5rem;
    }
  }

  .hiddenNav {
    visibility: hidden;
  }

  .withNavigateButtons {
    /**
    from lg we put the buttons on the inside of the container but the breakpoin here needs to be earlier as it needs to fit
    lg + button width + margins
    73.375 + 2*3.75 + 4*1.5rem
  */
    @media (min-width: 86.875rem) {
      margin-left: -3.75rem;
      margin-right: -3.75rem;
      max-width: calc(100% + 7.5rem);
    }
  }

  .swiperSlide {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: visible;
    height: auto; //the highest card in the swiper should determine the height of all others
  }
}
