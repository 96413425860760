@import 'libs/assets/theme/mixins.scss';

.news-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardWrapper {
  width: 21.875rem;
  height: 24.25rem;
  padding: 5px;
  box-sizing: border-box;
  @include below-sm {
    width: 100%;
    height: 26rem;
  }
}
