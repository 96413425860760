@import 'libs/assets/theme/mixins.scss';

.cardWrapper {
  &:global(.swiper-slide) {
    //we target the swiper-slide directly in order to have a higher specifity against the default styling
    position: relative;
    width: 16rem;
    padding: 5px;
  }
}
