@import 'libs/assets/theme/coreVariablesV2.scss';
@import 'libs/assets/theme/mixins.scss';

.each-category {
  cursor: pointer;
  width: 4rem;
  height: 4rem;
  position: relative;
  display: inline-block;
}
