@import 'libs/assets/theme/mixins.scss';

.checkbox {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 2rem;
  gap: 0.5rem;
  min-width: 2rem;
  @include lg {
    height: 1.25rem;
    min-width: 1.25rem;
  }
}

.elem {
  padding: 0;

  @include below-lg {
    padding: 0.25rem;
  }

  & > svg {
    width: 20px;
    height: 20px;

    @include below-lg {
      width: 24px;
      height: 24px;
    }
  }
}

.tooltip {
  &Icon {
    @include lg {
      height: 1rem;
      width: 1rem;
    }
  }
  &Title {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
}
